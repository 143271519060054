<template>
  <div id="tTrade">
    <a-form
      name="materialForm"
      class="ant-round-form material-fill"
      label-align="left"
      :label-col="{span: 10}"
      :model="materialForm"
      :rules="materialRules"
      @finish="materialSubmit"
    >
      <a-form-item
        v-if="materialForm.tradeType === '01' && !saleWebSite"
        name="saleWebSite"
        :label="$t('pages_receive_107')"
      >
        <a-input v-model:value="materialForm.saleWebSite" :placeholder="$t('pages_receive_108')" allow-clear />
      </a-form-item>
      <a-form-item name="payerName" :label="$t('pages_receive_085')">
        <a-input v-model:value="materialForm.payerName" :placeholder="$t('common_text_006')" allow-clear />
      </a-form-item>
      <a-form-item name="payerCountry" :label="$t('pages_receive_086')">
        <a-select
          v-model:value="materialForm.payerCountry"
          option-filter-prop="label"
          allow-clear
          show-search
          :placeholder="$t('common_text_005')"
        >
          <a-select-option
            v-for="item in areaPubs"
            :key="item.id"
            :value="item.areaEnglishName"
            :label="item.areaEnglishName + item.areaChineseName"
          >{{ item.areaEnglishName }} - {{ item.areaChineseName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item name="totalAmount" :label="$t('pages_receive_087')">
        <div style="position: relative">
          <a-input-number
            v-model:value="materialForm.totalAmount"
            style="position: relative; padding-left: 27%; overflow: clip"
            :controls="false"
            :min="0"
            :placeholder="$t('common_text_006')"
          />
          <a-form-item-rest>
            <a-select
              v-model:value="materialForm.currency"
              show-search
              class="area-select"
              :disabled="!isAdvance && !isUpdate"
              :bordered="false"
              :show-arrow="false"
            >
              <a-select-option
                v-for="item in curList"
                :key="item.currencyCode"
                :value="item.currencyCode"
                :label="item.currencyCode"
              >{{ item.currencyCode }}</a-select-option>
            </a-select>
          </a-form-item-rest>
        </div>
      </a-form-item>
      <a-form-item name="tradeDocumentsUrl" :label="$t('pages_receive_088')">
        <upload-auto
          ref="uploadDocRef"
          v-model:file-content="materialForm.tradeDocumentsUrl"
          upload-type="round"
          accept=".jpg,.png,.jepg,.pdf,.bmp,.zip,.rar"
          list-type="picture"
          :max-count="1"
        />
      </a-form-item>
      <a-form-item name="invoiceUrl" :label="$t('pages_receive_089')" style="position: relative">
        <upload-auto
          ref="uploadInvRef"
          v-model:file-content="materialForm.invoiceUrl"
          upload-type="round"
          accept=".jpg,.png,.jepg,.pdf,.bmp,.zip,.rar"
          list-type="picture"
          :max-count="1"
        />
        <div class="form-explain">
          <div>
            <span>{{ $t('pages_receive_095') }}</span>
            <span class="explain-text">{{ $t('pages_receive_096') }}</span>
            <span>{{ $t('pages_receive_097') }}</span>
            <span class="explain-text">{{ $t('pages_receive_098') }}</span>
            <span>{{ $t('pages_receive_099') }}</span>
          </div>
          <div>
            <span>{{ $t('pages_receive_100') }}</span>
            <span class="explain-text">{{ $t('pages_receive_101') }}</span>
            <span>{{ $t('pages_receive_102') }}</span>
          </div>
          <div>
            <span>{{ $t('pages_receive_103') }}</span>
          </div>
        </div>
      </a-form-item>
      <div v-if="materialForm.tradeType === '00'">
        <a-form-item name="logisticsStatus" :label="$t('pages_receive_090')">
          <a-radio-group v-model:value="materialForm.logisticsStatus" button-style="solid" @change="logisticsChange">
            <a-radio-button key="Y" value="1">{{ $t('pages_receive_093') }}</a-radio-button>
            <a-radio-button key="N" value="0">{{ $t('pages_receive_094') }}</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="materialForm.logisticsStatus === '1'" name="logisticsDocumentUrl" :label="$t('pages_receive_091')">
          <upload-auto
            ref="uploadLogRef"
            v-model:file-content="materialForm.logisticsDocumentUrl"
            upload-type="round"
            accept=".jpg,.png,.jepg,.pdf,.bmp,.zip,.rar"
            list-type="picture"
            :max-count="1"
          />
          <div class="form-explain">
            <div>
              <span>{{ $t('pages_receive_095') }}</span>
              <span>{{ $t('pages_receive_097') }}</span>
              <span class="explain-text">{{ $t('pages_receive_104') }}</span>
              <span>{{ $t('pages_receive_105') }}</span>
            </div>
            <div>
              <span>{{ $t('pages_receive_103') }}</span>
            </div>
          </div>
        </a-form-item>
      </div>
      <div v-else>
        <a-form-item name="isOver" :label="$t('pages_receive_109')">
          <a-radio-group v-model:value="materialForm.isOver" button-style="solid" @change="logisticsChange">
            <a-radio-button key="Y" value="1">{{ $t('pages_receive_111') }}</a-radio-button>
            <a-radio-button key="N" value="0">{{ $t('pages_receive_112') }}</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </div>
      <a-form-item v-if="materialForm.logisticsStatus === '0' || materialForm.isOver === '0'" name="planOverDate" :label="materialForm.tradeType === '00' ? $t('pages_receive_092') : $t('pages_receive_110')">
        <a-date-picker
          v-model:value="materialForm.planOverDate"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 10 }" style="margin-top: 64px">
        <a-row :wrap="false">
          <a-button
            :loading="loading"
            html-type="submit"
            type="primary"
            shape="round"
            class="material-btn"
          >{{ $t('common_text_002') }}</a-button>
          <a-button
            shape="round"
            style="margin-left: 18px"
            class="material-btn"
            type="primary"
            ghost
            @click="$router.back(-1)"
          >{{ $t('common_text_003') }}</a-button>
        </a-row>
      </a-form-item>
    </a-form>
    <div v-if="comments" class="error-area">
      <div class="title flex-align">
        <exclamation-circle-outlined class="ic-error" />
        <span>{{ $t('pages_receive_118') }}</span>
      </div>
      <div class="comments">{{ comments }}</div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, watch, onUnmounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import i18n from '@/locale'
import { _receive } from '@/api'
import { notification } from 'ant-design-vue'
import UploadAuto from '@/components/upload-auto'
export default {
  name: 'Ttrade',
  components: {
    'upload-auto': UploadAuto
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const uploadDocRef = ref(null)
    const uploadInvRef = ref(null)
    const uploadLogRef = ref(null)

    const state = reactive({
      areaPubs: [],
      curList: [],
      comments: '',
      vaId: '',
      loading: false,
      isAdvance: route.query.isAdvance,
      isUpdate: route.query.isUpdate,
      isReset: route.query.isReset,
      saleWebSite: '',
      materialForm: {
        bankAccountNo: '',
        saleWebSite: '',
        tradeType: '',
        payerName: '',
        payerCountry: null,
        currency: '',
        totalAmount: '',
        tradeDocumentsUrl: '',
        logisticsStatus: '1',
        logisticsDocumentUrl: '',
        planOverDate: '',
        isOver: '1'
      },
      materialRules: {
        saleWebSite: [{ required: true }],
        payerName: [{ required: true }],
        payerCountry: [{ required: true }],
        totalAmount: [{ required: true }],
        tradeDocumentsUrl: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_receive_088') }],
        invoiceUrl: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_receive_089') }],
        logisticsStatus: [{ required: true }],
        isOver: [{ required: true }],
        logisticsDocumentUrl: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_receive_091') }],
        planOverDate: [{ required: true }]
      },
      materialInfo: {}
    })

    onUnmounted(() => {
      sessionStorage.removeItem('vaList')
      sessionStorage.removeItem('materialInfo')
    })

    const logisticsChange = (e) => {
      if (e.target.value === '1') state.materialForm.planOverDate = null
      else state.materialForm.logisticsDocumentUrl = ''
    }

    const materialSubmit = async () => {
      state.loading = true
      const { materialInfo } = state
      const data = Object.assign({}, state.materialForm, {})
      if (state.materialForm.tradeType === '00') {
        delete data.saleWebSite
        delete data.isOver
      } else {
        delete data.logisticsStatus
        delete data.logisticsDocumentUrl
      }
      if (!state.isAdvance) {
        data.applyNo = materialInfo.applyNo
        data.amountLimit = materialInfo.fillAmt
        data.entryAmount = materialInfo.receiveAmount
        data.entryCurrency = materialInfo.currency
      }
      const res = await _receive.receiveTradeOrderSave(data)
      state.loading = false
      if (res.data.succ) {
        notification.success({
          message: i18n.global.t('common_text_018'),
          description: i18n.global.t('pages_receive_119')
        })
        router.back(-1)
      } else {
        notification.error({
          message: i18n.global.t('common_text_015'),
          description: res.data.resMsg
        })
      }
    }

    const loadAreaPub = async () => {
      const res = await _receive.getAreaPub()
      if (res.data.succ) {
        state.areaPubs = res.data.data
      }
    }

    const loadAllCurrency = async () => {
      const res = await _receive.getAllCurrency()
      if (res.data.succ) {
        state.curList = res.data.data
      }
    }

    const loadAllVAs = async () => {
      let vaList = sessionStorage.getItem('vaList')
      if (vaList) {
        vaList = JSON.parse(vaList)
      } else {
        const res = await _receive.getAllVAs()
        if (res.data.succ) vaList = res.data.data
      }
      if (state.vaId) {
        const vaInfo = vaList.find(va => va.id === state.vaId)
        state.materialForm.currency = vaInfo.currencyList[0]
        state.materialForm.tradeType = vaInfo.transType === '20' ? '00' : '01'
        state.materialForm.bankAccountNo = vaInfo.accountNo
        if (vaInfo.transType === '30') {
          state.saleWebSite = vaInfo.saleWebSite
          state.materialForm.saleWebSite = vaInfo.saleWebSite
        }
      }
    }

    const loadAccountDetails = async () => {
      const materialInfo = sessionStorage.getItem('materialInfo')
      if (materialInfo) {
        state.materialInfo = JSON.parse(materialInfo)
        state.materialForm.currency = state.materialInfo.currency
        state.materialForm.tradeType = state.materialInfo.transType === '20' ? '00' : '01'
        state.materialForm.bankAccountNo = state.materialInfo.receiveAccount
        if (state.materialInfo.transType === '30') {
          let vaList = sessionStorage.getItem('vaList')
          if (vaList) {
            vaList = JSON.parse(vaList)
          } else {
            const res = await _receive.getAllVAs()
            if (res.data.succ) vaList = res.data.data
          }
          const vaInfo = vaList.find(va => va.accountNo === state.materialInfo.receiveAccount)
          state.saleWebSite = vaInfo.saleWebSite
          state.materialForm.saleWebSite = vaInfo.saleWebSite
        }
      } else {
        router.replace('/home/index')
      }
    }

    const loadVaDetails = async () => {
      const { materialInfo } = state
      const res = await _receive.getReceiveTradeDetailByNo({ batchNo: materialInfo.batchNo })
      if (res.data.succ) {
        const { data } = res.data
        state.comments = data.comments
        state.materialForm.isModify = materialInfo.status === '08' ? '1' : '0'
        if (materialInfo.status === '08') state.materialForm.id = data.id
        state.materialForm.payerName = data.payerName
        state.materialForm.payerCountry = data.payerCountry
        state.materialForm.totalAmount = data.totalAmount
        state.materialForm.currency = data.currency
        state.materialForm.tradeDocumentsUrl = data.tradeDocumentsUrl
        state.materialForm.invoiceUrl = data.invoiceUrl
        state.materialForm.planOverDate = data.planOverDate === 'null' ? null : data.planOverDate
        if (data.tradeDocumentsUrlList) uploadDocRef.value.fileListRefresh(data.tradeDocumentsUrlList)
        if (data.invoiceUrlList) uploadInvRef.value.fileListRefresh(data.invoiceUrlList)
        if (materialInfo.transType === '20') {
          state.materialForm.logisticsDocumentUrl = data.logisticsDocumentUrl
          state.materialForm.logisticsStatus = data.logisticsStatus
          if (data.logisticsDocumentUrlList) uploadLogRef.value.fileListRefresh(data.logisticsDocumentUrlList)
        } else {
          state.saleWebSite = data.saleWebSite
          state.materialForm.saleWebSite = data.saleWebSite
          state.materialForm.isOver = data.isOver
        }
      }
    }

    const initPage = () => {
      if (route.query.vaId) state.vaId = +route.query.vaId
      loadAreaPub()
      loadAllCurrency()
      if (state.isAdvance) loadAllVAs()
      else loadAccountDetails()
      if (state.isReset || state.isUpdate) loadVaDetails()
    }

    watch(
      () => router.currentRoute.value,
      (value) => {
        if (value.name === 'materialTrade') initPage()
      },
      { immediate: true }
    )

    return {
      uploadDocRef,
      uploadInvRef,
      uploadLogRef,
      materialSubmit,
      logisticsChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#tTrade {
  display: flex;
  margin-top: 12px;
  .material-fill {
    width: 500px;
    .area-select {
      left: 1px;
      top: 1px;
      bottom: 1px;
    }
    .ant-form-item-has-error:deep(.area) {
      border: 1px solid @error-color !important;
    }
    .form-explain {
      background: @table-th-color;
      padding: 12px 36px;
      border-radius: 500px;
      font-size: 12px;
      display: inline-block;
      margin-top: 12px;
      width: 688px;
      .explain-text {
        color: @main-color;
      }
    }
    .material-btn {
      min-width: 138px;
    }
  }
  .error-area {
    width: 250px;
    height: 300px;
    margin-left: 88px;
    padding: 12px 24px;
    border-radius: 7px;
    color: @error-color;
    background: #fef0f0;
    overflow-y: scroll;
    word-break: break-word;
    .title {
      font-weight: 500;
      .ic-error {
        font-size: 20px;
        margin-right: 12px;
      }
    }
    .comments {
      white-space: pre-wrap;
      margin-top: 12px;
    }
  }
}
</style>

